import { round, toFloat } from '~/utils/numbers';
import { createEvent } from '~/utils/eventable';

export const TIP_OPTIONS = [15, 20, 25];

export const getFeeAmount = (menuItemCart, values) => {
  if (
    values.paymentMethod === 'stripe_payment_method' ||
    values.paymentMethod === 'square_payment_method' ||
    values.paymentMethod === 'toast_payment_method' ||
    values.paymentMethod === 'clover_payment_method' ||
    values.paymentMethod === 'heartland_payment_method' ||
    values.paymentMethod === 'ncr_payment_method' ||
    values.paymentMethod === 'authorize_payment_method' ||
    values.paymentMethod === 'everyware_payment_method'
  ) {
    return (menuItemCart.onlineFeeAmount || 0) + (menuItemCart.platformFeeAmount || 0);
  } else {
    return 0;
  }
};

export const getCustomFeeAmount = menuItemCart => menuItemCart.applicableCustomFees.reduce((accumulator, obj) => obj.finalAmount + accumulator, 0);

export const getBagFeeAmount = (menuItemCart, values) => {
  if (
    values.paymentMethod === 'stripe_payment_method' ||
    values.paymentMethod === 'square_payment_method' ||
    values.paymentMethod === 'toast_payment_method' ||
    values.paymentMethod === 'clover_payment_method' ||
    values.paymentMethod === 'heartland_payment_method' ||
    values.paymentMethod === 'authorize_payment_method' ||
    values.paymentMethod === 'everyware_payment_method' ||
    values.paymentMethod === 'pickup_payment_method'
  ) {
    return (menuItemCart.onlineBagFeeAmount || 0);
  } else {
    return 0;
  }
};

export const getExpectedTotal = (menuItemCart, values) => {
  const isStripePaymentMethod = values.paymentMethod === 'stripe_payment_method';
  const isSquarePaymentMethod = values.paymentMethod === 'square_payment_method';
  const isToastPaymentMethod = values.paymentMethod === 'toast_payment_method';
  const isCloverPaymentMethod = values.paymentMethod === 'clover_payment_method';
  const isHeartlandPaymentMethod = values.paymentMethod === 'heartland_payment_method';
  const isNcrPaymentMethod = values.paymentMethod === 'ncr_payment_method';
  const isAuthorizePaymentMethod = values.paymentMethod === 'authorize_payment_method';
  const isEverywarePaymentMethod = values.paymentMethod === 'everyware_payment_method';

  let tipAmount = 0;
  if (isStripePaymentMethod) tipAmount = (round(toFloat(values.stripeTipAmount), 2) || 0);
  if (isSquarePaymentMethod) tipAmount = (round(toFloat(values.squareTipAmount), 2) || 0);
  if (isToastPaymentMethod) tipAmount = (round(toFloat(values.toastTipAmount), 2) || 0);
  if (isCloverPaymentMethod) tipAmount = (round(toFloat(values.cloverTipAmount), 2) || 0);
  if (isHeartlandPaymentMethod) tipAmount = (round(toFloat(values.heartlandTipAmount), 2) || 0);
  if (isNcrPaymentMethod) tipAmount = (round(toFloat(values.ncrSilverTipAmount), 2) || 0);
  if (isAuthorizePaymentMethod || isEverywarePaymentMethod) tipAmount = (round(toFloat(values.onlineTipAmount), 2) || 0);
  const taxAmount = (menuItemCart.onlineTaxAmount || 0);
  const feeAmount = getFeeAmount(menuItemCart, values);
  const bagFeeAmount = getBagFeeAmount(menuItemCart, values);
  const customFeeAmount = getCustomFeeAmount(menuItemCart);
  const deliveryFeeAmount = menuItemCart.fulfillmentType === 'delivery_fulfillment_type' ? (menuItemCart.deliveryFeeAmount || 0) : 0;
  const total = (menuItemCart.subtotal - menuItemCart.discountAmount || 0) + taxAmount + feeAmount + bagFeeAmount + tipAmount + deliveryFeeAmount + customFeeAmount;
  let giftCardDiscountAmount = 0;
  let cantFullyCoverTipWithGiftCard = false;
  if (menuItemCart.selectedGiftCard || menuItemCart.selectedEcard) {
    const giftCardAmount = menuItemCart.selectedGiftCard ? menuItemCart.selectedGiftCard.giftCard.amount : menuItemCart.selectedEcard.originalBalance;
    if (giftCardAmount >= total) {
      giftCardDiscountAmount = total;
    } else if (isCloverPaymentMethod && tipAmount !== 0) {
      // clover tips can't be partially paid by gift card and partially by cc. They should be fully paid with one payment
      if (giftCardAmount <= total - tipAmount) {
        giftCardDiscountAmount = giftCardAmount;
      } else {
        giftCardDiscountAmount = total - tipAmount;
        cantFullyCoverTipWithGiftCard = true;
      }
    } else {
      giftCardDiscountAmount = giftCardAmount;
    }
  }

  return {
    cantFullyCoverTipWithGiftCard,
    feeTotal: customFeeAmount + feeAmount,
    giftCardDiscountAmount,
    // We don't want to pass to BE values like 1.79499999 which actually represents 1.795
    // The thing is the first one will give $1.79 and the second one $1.8 after bankers rounding
    // Let's round to 3, it guarantees correct bankers rounding on BE
    total: round(total - giftCardDiscountAmount, 3),
  };
};

export const getOnlinePaymentAvailability = (menuItemCart) => {
  switch (menuItemCart.paymentMethod) {
    case 'authorize_payment_method':
      return menuItemCart.location.isAuthorizePaymentAvailable;
    case 'everyware_payment_method':
      return menuItemCart.location.isEverywarePaymentAvailable;
    case 'heartland_payment_method':
      return menuItemCart.location.isHeartlandPaymentAvailable;
  }

  return false;
};

export const getTipFieldName = (menuItemCart) => {
  switch (menuItemCart.paymentMethod) {
    case 'stripe_payment_method':
      return 'stripeTipAmount';
    case 'square_payment_method':
      return 'squareTipAmount';
    case 'heartland_payment_method':
      return 'heartlandTipAmount';
    case 'toast_payment_method':
      return 'toastTipAmount';
    case 'clover_payment_method':
      return 'cloverTipAmount';
    case 'ncr_payment_method':
      return 'ncrSilverTipAmount';
  }

  return 'onlineTipAmount';
};

export const guestProfileEventTracking = (menuItemCart) => {
  if (menuItemCart.profileEventTracking.length > 0) {
    menuItemCart.profileEventTracking.forEach((event) => {
      createEvent({
        eventableType: 'GuestProfile',
        eventLabel: `order_submitted | ${event === 're_order_all' ? 're_order_all' : `${event}_redemption`}`,
        eventType: `order_submitted_${event === 're_order_all' ? 're_order_all' : `${event}_redemption`}`,
      });
    });
  }
  if (menuItemCart.selectedMenuItems.some(item => item.profileEventTracking === 're_order')) {
    createEvent({
      eventableType: 'GuestProfile',
      eventLabel: 'order_submitted | re_order',
      eventType: 'order_submitted_re_order',
    });
  }
  if (menuItemCart.selectedMenuItems.some(item => item.profileEventTracking === 'remind_to_try')) {
    createEvent({
      eventableType: 'GuestProfile',
      eventLabel: 'order_submitted | remind_me_to_try',
      eventType: 'order_submitted_remind_me_to_try',
    });
  }
};

// Only run this part when we are using webhooks to verify orders
// sc-102550 - remove second_submission_backend_aloha
// remove continueWhenCartIsSubmittedOnCallback when second_submission_backend_aloha is removed
export const pollMenuItemCartUntilConfirmed = (refetchMenuItemCartConfirmed, validate, continueWhenCartIsSubmittedOnCallback, t) => new Promise((resolve, reject) => {
  const maxTotalWaitTimeSec = 30;
  let curTotalSpentSec = 0;
  let prevFetchTimeoutSec = 0;
  let fetchTimeoutSec = 1;

  function isTimeout() {
    return maxTotalWaitTimeSec <= curTotalSpentSec;
  }

  // We are increasing the next fetch timeout in fibonacci sequence
  function nextFetchTimeout() {
    curTotalSpentSec += fetchTimeoutSec;

    const oldFetchTimeoutSec = fetchTimeoutSec;
    fetchTimeoutSec += prevFetchTimeoutSec;
    prevFetchTimeoutSec = oldFetchTimeoutSec;

    // We don't want to go beyond the maxTotalWaitTimeSec == 30
    const futureTotalSpentSec = curTotalSpentSec + fetchTimeoutSec;
    if (futureTotalSpentSec > maxTotalWaitTimeSec) {
      fetchTimeoutSec = maxTotalWaitTimeSec - curTotalSpentSec;
    }

    return fetchTimeoutSec;
  }

  async function fetch() {
    const { data, error } = await refetchMenuItemCartConfirmed();

    if (error) reject(t('errors.failed_retry'));

    if (!validate && data.menuItemCart.isPosOrderConfirmed &&
      ((continueWhenCartIsSubmittedOnCallback && data.menuItemCart.isSubmitted) ||
        (!continueWhenCartIsSubmittedOnCallback))) {
      resolve({ isPosOrderConfirmed: true });
    } else if (validate && data.menuItemCart.isPosOrderValidated) {
      resolve({ isPosOrderValidated: true });
    } else if (data.menuItemCart.isPosOfferFailed) {
      reject(t('models.menu_item_cart.pos_offer_failed'));
    } else if (data.menuItemCart.isPosOrderFailed) {
      reject(t('models.menu_item_cart.pos_order_failed'));
    } else if (!isTimeout()) {
      setTimeout(fetch, nextFetchTimeout() * 1000);
    } else {
      reject(t('models.timeout'));
    }
  }

  fetch();
});

export const shouldShowOrderingNotification = (cart) => {
  const { location } = cart;
  const { isOrderingMessagesEnabled, isOrderingTextMessagesEnabled } = location;
  return isOrderingMessagesEnabled && isOrderingTextMessagesEnabled;
};

export const defaultTipPercent = (defaultTipOption, isDoordashDelivery) => {
  let tipAmount;
  if (isDoordashDelivery) {
    tipAmount = 20 * 0.01;
  } else if (defaultTipOption === null) {
    tipAmount = 0;
  } else {
    tipAmount = defaultTipOption * 0.01;
  }
  return tipAmount;
};

export const defaultTipOption = (menuItemCart, isDoordashDelivery) => {
  let option;
  if (isDoordashDelivery) {
    option = '20';
  } else {
    option = menuItemCart.location.defaultTipOption;
  }
  return option;
};
